body {
  font-family: 'Source Code Pro', monospace;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  text-align: center;
}

h1 {
  font-family: 'Poiret One', cursive;
  font-size: 36px;
  margin-bottom: 20px;
}

p {
  font-family: 'Source Code Pro', monospace;
  font-size: 18px;
  margin-bottom: 30px;
}

.progress-bar {
  width: auto;
  height: 20px;
  background-color: #f3f3f3;
  border-radius: 0px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #000000;
  width: 0;
  transition: width 1s ease-in-out;
  text-align: right;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding-right: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
